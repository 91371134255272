.animate-letter {
  animation-name: upAndDown, slamDown;
  animation-duration: 0.45s, 0.3s;
  animation-timing-function: ease-in-out, ease-in;
  animation-fill-mode: forwards;
}


@keyframes upAndDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

@keyframes slamDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
}
