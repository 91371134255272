.simple-keyboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  
  .simple-keyboard .hg-row {
    justify-content: center;
    width: 100%;
  }
  
  .simple-keyboard .hg-row:first-of-type {
    justify-content: space-between;
  }

  .simple-keyboard .hg-row:last-of-type {
    justify-content: space-around; 
    
  }

  .simple-keyboard .hg-button {

    margin: 1px;
    min-width: 8vw;
    max-width: 8vw; 
    height: 12vw;
    max-height: 60px;
    font-size: 6.5vw; 
    text-align: center;
    border-radius: 5px; 
  }
  

  
  /* CustomStyles.css */
.backspaceKey {
    font-size: 24px !important; 
    max-width: 12vw !important;
    min-width: 12vw !important;
}
  
  .enterKey {
    font-size: 12px !important; 
    max-width: 12vw !important;
    min-width: 12vw !important;
  }

@media (min-width: 601px) {
    .simple-keyboard {
      margin: 0 auto;
      max-width: 900px; 
    }
  
    .simple-keyboard .hg-row {
      justify-content: space-between;
    }
  
    .simple-keyboard .hg-row:first-of-type {
        justify-content: space-between;
      }
      
    .simple-keyboard .hg-button {
      margin: 2px;
      min-width: 60px; 
      height: 60px;
      font-size: 24px; 
    }
  
    .backspaceKey {
        font-size: 30px !important; 
        min-width: 60px !important; 
        max-width: 120px !important; 
        height: 60px !important;
      }
      
      .enterKey {
        font-size: 16px !important; 
        min-width: 60px !important; 
        max-width: 120px !important; 
        height: 60px !important;
      }
      
      .startOverKey {
        font-size: 38px !important; 
        width: 60px !important;
        height: 60px !important;
      }
    
      .showCWBestKey {
        font-size: 14px !important;
        width: 60px !important;
        height: 60px !important;
      }
  
    .custom-award-icon::after {
      width: 1.5em;
      height: 1.5em;
    }
  }
  
